import { Center, Spinner, Text } from "@chakra-ui/react";
import { BrowserRouter, RouteObject, useRoutes } from "react-router-dom";
import { Suspense, lazy } from "react";
import CaseEnquiry from "./dashboard/case-enquiry/CaseEnquiry";
import { retry } from "utils/retyImport";
import PayOutstanding from "@dashboard/wallet/PayOutstanding";
import SuccessPayment from "@dashboard/utils/SuccessPayment";
import AdditionalDetails from "@dashboard/additional-details/AdditionalDetails";
import BTHSlip from "@dashboard/case-list/BTHSlip";

const Login = lazy(() => retry(() => import("./auth/Login/Login")));
const ControlTower = lazy(() => retry(() => import("./dashboard/control-tower/ControlTower")));
const LightHouse = lazy(() => retry(() => import("./dashboard/lighthouse/LightHouse")));
const Events = lazy(() => retry(() => import("./dashboard/case-events/Events")));
const CaseList = lazy(() => retry(() => import("./dashboard/case-list/CaseList")));
const PersonnelList = lazy(() => retry(() => import("./dashboard/personnel-list/PersonnelList")));
const WalletDetails = lazy(() => retry(() => import("./dashboard/wallet-details/WalletDetails")));
const FleetTracing = lazy(() => retry(() => import("./dashboard/fleet-tracing/FleetTracing")));
const EntityTracing = lazy(() => retry(() => import("./dashboard/entity-tracing/EntityTracing")));
const SideBar = lazy(() => retry(() => import("../components/navigation")));
const Profile = lazy(() => retry(() => import("./dashboard/_profile/Profile")));
const Logout = lazy(() => retry(() => import("./auth/Logout/Logout")));
const NotFound = lazy(() => retry(() => import("./NotFound")));
const ForbiddenPage = lazy(() => retry(() => import("./ForbiddenPage")));

const Case = lazy(() => retry(() => import("./case/Case")));
const CreateCase = lazy(() => retry(() => import("./dashboard/create-case/CreateCase")));
const CaseHistory = lazy(() => retry(() => import("./dashboard/case-history/CaseHistory")));
const CreateModal = lazy(() => retry(() => import("./dashboard/create-case/CreateModal")));
const Assignment = lazy(() => retry(() => import("./dashboard/assignment/Assignment")));
const Payment = lazy(() => retry(() => import("./dashboard/payment/Payment")));
const CollectOnline = lazy(() => retry(() => import("./dashboard/payment/CollectOnline")));
const WalletInfo = lazy(() => retry(() => import("./dashboard/wallet-details/wallet/WalletInfo")));
const CRM = lazy(() => retry(() => import("./dashboard/create-case/Crm")));
const QuickLink = lazy(() => retry(() => import("./dashboard/quick-link/QuickLink")));
const VehicleAudit = lazy(() => retry(() => import("./dashboard/case-history/VehicleAudit")));
const ReadyToDispatch = lazy(() => retry(() => import("./dashboard/ready-to-dispatch/ReadyToDispatch")));

const Transactions = lazy(() => retry(() => import("@dashboard/wallet/Transactions")));
const Outstanding = lazy(() => retry(() => import("@dashboard/wallet/Outstanding")));
const Tutorial = lazy(() => retry(() => import("@dashboard/tutorial/Tutorial")));

const PosPaymentLanding = lazy(() => retry(() => import("@features/pos/pos-payment-landing")));

import * as Sentry from "@sentry/react";

export const Loader = () => <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />;

const InnerRouter = () => {
  const routes: RouteObject[] = [
    {
      path: "/",
      children: [
        {
          path: "auth",
          children: [
            {
              index: true,
              element: <Login />,
            },
            {
              path: "login",
              element: <Login />,
            },
            {
              path: "logout",
              element: <Logout />,
            },
          ],
        },
        {
          path: "",
          element: <SideBar />,
          children: [
            {
              index: true,
              element: <CaseList />,
            },
            {
              path: "quick-link",
              element: <QuickLink />,
            },
            {
              path: "analytics",
              element: <ControlTower />,
            },
            {
              path: "light-house",
              element: <LightHouse />,
            },
            {
              path: "case/:id/events",
              element: <Events />,
            },
            {
              path: "case/:id",
              element: <Case />,
            },
            {
              path: "crm/:id",
              element: <CRM />,
            },
            {
              path: "case/:id/ready-to-dispatch",
              element: <ReadyToDispatch />,
            },
            {
              path: "create-case",
              element: <CreateModal />,
            },
            {
              path: "tutorial",
              element: <Tutorial />,
            },
            {
              path: "create-case/:id",
              element: <CreateCase />,
            },
            {
              path: "case-enquiry/:id",
              element: <CaseEnquiry />,
            },
            {
              path: "case-history/:id/case-overview",
              element: <CaseHistory />,
            },
            {
              path: "case/:id/vehicle-audit",
              element: <VehicleAudit />,
            },
            {
              path: "bth-slip",
              element: <BTHSlip />,
            },
            {
              path: "case-list",
              element: <CaseList />,
            },
            {
              path: "case/:id/assignment",
              element: <Assignment />,
            },
            {
              path: "case/:id/payment/collect-online",
              element: <CollectOnline />,
            },
            {
              path: "case/:id/payment/pos-status",
              element: <PosPaymentLanding />,
            },
            {
              path: "case/:id/payment",
              element: <Payment />,
            },
            {
              path: "case/:id/additional-details",
              element: <AdditionalDetails />,
            },
            {
              path: "personnel-list",
              element: <PersonnelList />,
            },
            {
              path: "payment-success",
              element: <SuccessPayment />,
            },
            {
              path: "wallet-details",
              element: <WalletDetails />,
            },

            {
              path: "wallet/outstanding/pay",
              element: <PayOutstanding />,
            },
            {
              path: "wallet/outstanding",
              element: <Outstanding />,
            },
            {
              path: "wallet/transactions",
              element: <Transactions />,
            },
            {
              path: "wallet/:id",
              element: <WalletInfo />,
            },
            {
              path: "fleet-tracing",
              element: <FleetTracing />,
            },
            {
              path: "entity-tracing",
              element: <EntityTracing />,
            },
            {
              path: "profile",
              element: <Profile />,
            },
          ],
        },
        {
          path: "/403",
          element: <ForbiddenPage />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ];

  const element = useRoutes(routes);

  return (
    <div>
      <Suspense
        fallback={
          <Center height="100vh">
            <Loader />
          </Center>
        }
      >
        {element}
      </Suspense>
    </div>
  );
};

const Navigation = () => (
  <BrowserRouter>
    <Sentry.ErrorBoundary fallback={<Text>"An error has occurred"</Text>}>
      <InnerRouter />
    </Sentry.ErrorBoundary>
  </BrowserRouter>
);

export default Navigation;
